





















/* eslint-disable @typescript-eslint/camelcase */ 

    import {  arquivoService } from '@/service';
	
    import _formAdicionar from './_formAdicionar.vue' 

	import $ from "jquery"  
 
    import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: {
            'formAdicionar' :  _formAdicionar, 
         },
    })
    export default class IndexArquivoCompartilhar extends Vue { 

        private  reloadDatatable = false ; 

        private arquivo: any= null; 
		private url_retorno: any=  '/v2/geral/arquivo'  ;  
        

        private config = {  
            order: [  [0, "asc"] ],
            ajax: {
                url: arquivoService.getUrl() + '/' + this.$route.params.id + '/usuario/datatable',
            },
            columns: [ 
                { data: 'usuario_id', name: 'arquivo_usuario_compartilhado.usuario_id',  class: 'text-center' },
                { data: 'nome',  name: 'users.nome' },  
                { data: 'action',  name: 'action', orderable: false, searchable: false,  class: 'text-center' }, 
            ],

        };
 

        
        created() { 
            window.alertProcessando(); 
			arquivoService.getModel( this.$route.params.id )  
			.then(response => {
				 window.alertProcessandoHide();
				this.arquivo = response;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o Modulo', error.data);
				window.alertProcessandoHide();
				this.$router.push(this.url_retorno);
			}); 
        }

        
		arquivoCompartilhado(event: any) { 
			this.reloadDatatable = !this.reloadDatatable; 
		}


        public tableatualizada( ) {  
            $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma o Descompartilhamento ','' , 
                     () => {  
                        window.alertProcessando(); 
                        arquivoService.desCompartilhar( this.$route.params.id ,   id )
                        .then(response => {
							window.alertProcessandoHide();   
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Disciplina removido com sucesso!!' ); 
                        })
                        .catch(error => { 
							this.reloadDatatable = !this.reloadDatatable; 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel remover a Disciplina' , ''); 
                        });  
                    }
                ); 
            });
        }
    }
 
