





















































































































































































































































































































































































































































































































 

    import { Component, Vue } from 'vue-property-decorator';
    

    import { namespace } from 'vuex-class' 
    const ambiente = namespace('ambiente')
    const usuario = namespace('usuario')

    import { moduloGeralService } from '@/service'; 



    @Component({
        components: { },
    })
    export default class Principal extends Vue { 

        private valordoacao: any = null; 


        @usuario.State
        public status!: any

        @ambiente.State
		public modulo!: any

        @ambiente.Action
        public buscarModulo!: (  ) => void
 

        @ambiente.Action
        public alterarModuloCompra!: (modulo: any) => void;

        private urlApp = 'https://play.google.com/store/apps/details?id=guiaonlinechs.com.br';
        private urlAppIos = 'https://apps.apple.com/br/app/guia-online-cursos/id1610641675?platform=iphone';

        // private urlPicPay = 'https://picpay.me/elisandra.nascimento.manzoli';
        private urlPicPay = 'https://picpay.me/bruno.manzoli.nascimento/';


        private urlInstagram = 'https://www.instagram.com/guiaonlinecursos/';

        private modulos: any = null;

        created() { 
            if(!this.modulo && this.loggingIn){
                this.buscarModulo();
			}   

            window.alertProcessando(); 
            moduloGeralService.getAllDisponivel( )
                .then(response => {
                    this.modulos = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar a Modulo', error.data);
                    window.alertProcessandoHide(); 
                });
 

        }




        adquirir(modulo){
            if(!this.loggingIn){
                window.toastErro('Faça seu Login primeiro!!', '');
            }
            else{ 
                this.alterarModuloCompra(modulo);
                this.$router.push('/v2/geral/pagamento'); 
            }
        }




        atualizarModulos(){
            window.alertProcessando(); 
            
            moduloGeralService.getAllDisponivel( true )
                .then(response => {
                    this.modulos = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar a Modulo', error.data);
                    window.alertProcessandoHide(); 
                });
        }

        get loggingIn(): boolean {
            if (this.status) {
                return  this.status.loggedIn ;
            }
            return false;
        }

 


    }
