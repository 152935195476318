
// const  url =  'http://chs80.usuario/api/v1' ; 
// const  urlSite =  'http://chs80.usuario' ; 
// const  urlResposta =  'https://consultoria.guiaonlinechs.com.br/api/v1' ; 
 

// const  urlResposta =  'https://consultoria.guiaonlinechs.com.br/api/v1' ; 
// const  url =  'https://api.guiaonlinechs.com.br/api/v1' ; 
// const  urlSite =  'https://api.guiaonlinechs.com.br' ; 
 

const  urlResposta =  'https://respostas.guiaonlinecursos.com.br/api/v1' ; 
// const  url =  'https://api.guiaonlinecursos.com.br/api/v1' ; 
// const  urlSite =  'https://api.guiaonlinecursos.com.br' ; 

const  url =  'https://militar.guiaonlinecursos.com.br/api/v1' ; 
const  urlSite =  'https://militar.guiaonlinecursos.com.br' ; 



// const  url =  'http://192.168.1.84:8080/api/v1' ; 
// const  urlSite =  'http://192.168.1.84:8080' ; 


const  apiMap =  'AIzaSyCxGKoF5sKhGhQCxD3h-i0zdbd_mQwyvhc' ; 


 
function getApi(  ) { 
	return  url; 
}
 
function getSiteApi(  ) { 
	return  urlSite; 
}
 
function getApiResposta(  ) { 
	return  urlResposta; 
}


function getApiMap(  ) { 
	return  apiMap; 
}



export const apiModel = { 
	getApi,  
	getApiMap, 
	getSiteApi, 
	getApiResposta,
};


 