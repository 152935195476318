



































































 

    import { moduloGeralService } from '@/service'; 


    import { Component, Vue } from 'vue-property-decorator';
	import moment  from 'moment';
    
    import { namespace } from 'vuex-class'
    
    import FormTermoUso from './_termo.vue'
    
    
    const ambiente = namespace('ambiente')
      


    @Component({
        components: {
	        FormTermoUso,
        },
    })
    export default class Modulos extends Vue { 


        @ambiente.State
		public modulo!: any

		private modulos: any = null;

        @ambiente.Action
        public alterarModulo!: ( modulo: any ) => void
        
        
        @ambiente.Action
        public resetModulo!: (  ) => void

        

        created() {
 
            window.alertProcessando(); 
            moduloGeralService.getAll( )
                .then(response => {
                    this.modulos = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar a Modulo', error.data);
                    window.alertProcessandoHide(); 
                });

        }
    


        atualizarModulos(){
            window.alertProcessando(); 
            this.resetModulo();
            moduloGeralService.getAll( true )
                .then(response => {
                    this.modulos = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar a Modulo', error.data);
                    window.alertProcessandoHide(); 
                });
        }


        
		databr(value: any): string {
			return moment(value).format("DD/MM/YYYY");
		}
    }
