import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router' 
import Principal from '../views/Principal.vue'
import Login from '@/views/login/Login.vue';
import EsqueciSenha from '@/views/login/EsqueciSenha.vue';
 
import * as KJUR from "jsrsasign" ; 
    
import routesGeral from './geral'; 

import BarraLateral from '@/components/layout/BarraLateral.vue' 
import Header from '@/components/layout/Header.vue'  
import Footer from '@/components/layout/Footer.vue'


import Cadastro from '@/views/Cadastro.vue'; 
import Termo from '@/views/Termo.vue'; 
import Promocao from '@/views/Promocao.vue'; 
import Privacidade from '@/views/Privacidade.vue'; 

import ProfileIndex from '@/views/profile/Index.vue';
import ProfileAlterarSenha from '@/views/profile/alterarSenha.vue';
 
  
import SemanalClassificacao from '@/views/semanal/Classificao.vue'; 
import LoginToken from '@/views/login/LoginDireto.vue'; 
 


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	{
		path: '/',
		name: 'Home',
		components: {
			default: Principal,
			sidebar: BarraLateral,
			header: Header, 
			footer: Footer,
		}, 
	},
 

  	{
		path: '/login', 
		components: {
			// default: Login,
			// sidebar: BarraLateral,
			// header: Header,
			header: Login,
			// footer: Footer,
		},
  	},

  	{
		path: '/recuperar_senha', 
		components: {
			// default: Login,
			// sidebar: BarraLateral,
			// header: Header,
			header: EsqueciSenha,
			// footer: Footer,
		},
  	},
  
  	{
		path: '/termo_de_uso', 
		components: {
			default: Termo,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
  	},

	{
		path: '/promocao', 
		components: {
			default: Promocao,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
  	},
  
  
  	{
		path: '/privacidade', 
		components: {
			// default: Privacidade,
			// sidebar: BarraLateral,
			header: Privacidade,
			// footer: Footer,
		},
  	},
  
 
	{
		path: '/login/token/:token', 
		components: {
			default: LoginToken,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
	},

	{
		path: '/cadastro', 
		components: {
			// default: Cadastro,
			// sidebar: BarraLateral,
			header: Cadastro,
			// header: Header,
			// footer: Footer,
		},
	},
 
	{
		path: '/profile', 
		components: {
			default: ProfileIndex,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},
 

	{
		path: '/alterar/senha',  
		components: {
			default: ProfileAlterarSenha,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},

	
	 
 
  
];

 

routesGeral.forEach(element => {
	routes.push(element);
});

 




routes.push({ path: '*', redirect: '/' });


  


const router = new VueRouter({
  routes
})






router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		const user = localStorage.getItem('user');
		if (!user) {
			next({
				path: '/login',
				query: {
					redirect: to.fullPath,
				},
			});
		}
		else if (to.matched.some(record => record.meta.requiresPerfil)) { 
			const userObject = JSON.parse(user); 
			const perfis = KJUR.jws.JWS.parse(userObject).payloadObj.perfis; 
		 
			if (perfis.filter(
				( perfil: any ) => {
					let temp = false 
					to.meta.requiresPerfil.every( (element:  any ) => { 
						if (perfil == element) { 
							temp = true; 
							return false;
						}
						return true
					});

					return temp;

				}).length > 0) { 
				next();
			}
			else { 
				next({
					path: '/',
					query: {
					},
				});
			}
		}
		else {
			next();
		} 

	} else {
		next();
	}
})





export default router
